import { connect } from 'react-redux';
import { withFormik } from 'formik';
import { compose } from 'redux';
import { mergeWith, isEmpty } from 'lodash';

import validationSchema from './SocialMedia.validation';
import logger from '../../../../../core-components/Logger';
import { getErrorMessage, errToastMessage } from '../../../../../utils/utilities';

import {
  saveBgvCache as saveBgvCacheAPI,
  setBgvSubmission as setBgvSubmissionAPI,
  getBgvCandidateDetails as getBgvCandidateDetailsAPI
} from '../../../../../actions/bgv';

const mapDispatchToProps = (dispatch) => {
  return {
    setBgvCache: (data) => dispatch(saveBgvCacheAPI(data)),
    setBgvSubmission: (data) => dispatch(setBgvSubmissionAPI(data)),
    getBgvCandidateDetails: (data) => dispatch(getBgvCandidateDetailsAPI(data))
  };
};
const mapStateToProps = (state) => {
  return {
    cached_data: !state.bgvCachedData.data ? null : { ...state.bgvCachedData.data },
    bgv_config: !isEmpty(state.bgvPackageConfig.data) ? state.bgvPackageConfig.data : null,
    isLoadingBgvCache: state?.bgvCachedData?.loading
  };
};
const formattedSaveObject = (props, values) => {
  let finalCacheObj = {};
  let saveObject = {};

  if (!props.cached_data) {
    saveObject['cache_data'] = {
      social_media: {
        ...values
      }
    };
  } else {
    saveObject = {
      ...props.cached_data,
      cache_data: {
        ...props.cached_data.cache_data,
        social_media: {
          ...values
        }
      }
    };
  }
  finalCacheObj['saveObject'] = {
    ...saveObject
  };
  return finalCacheObj;
};

let propsValue = {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withFormik({
    mapPropsToValues: (props) => {
      let storedValues = {
        linkedin_url: '',
        facebook_url: '',
        twitter_url: '',
        instagram_url: '',
        is_linkedin_url_not_available: false,
        is_facebook_url_not_available: false,
        is_twitter_url_not_available: false,
        is_instagram_url_not_available: false,
        social_media_config: props?.bgv_config?.config.social_media
      };
      if (
        props.cached_data &&
        !isEmpty(props.cached_data) &&
        props.cached_data.cache_data &&
        props.cached_data.cache_data['social_media']
      ) {
        propsValue = props.cached_data.cache_data['social_media'];
      }
      return mergeWith({}, storedValues, propsValue, (a, b) => (b === null ? a : b));
    },
    enableReinitialize: true,
    validationSchema,
    validateOnMount: true,
    handleSubmit: async (values, { setStatus, setSubmitting, resetForm, props }) => {
      let valuesCopy = Object.assign({}, values);
      const finalCacheObj = formattedSaveObject(props, valuesCopy);
      props
        .setBgvCache(finalCacheObj)
        .then(() => {
          setSubmitting(false);
          const loggerObj = {
            message: 'Social Media Step',
            data: JSON.stringify(values),
            category: `social-media-${props.candidateId}`,
            subCategory: 'Social Media',
            type: 'info'
          };
          logger.push(loggerObj);
          props.nextStep(true);
        })
        .catch((error) => {
          console.error(error);
          setSubmitting(false);
          const loggerObj = {
            message: getErrorMessage(error),
            data: JSON.stringify(values),
            category: `social-media-${props.candidateId}`,
            subCategory: 'Social Media',
            type: 'error'
          };
          logger.push(loggerObj);
          errToastMessage(error);
        });
    },
    displayName: 'Social Media'
  })
);
