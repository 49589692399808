// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fk4mkhb1a8F7UxJ8OlE8 {
  margin-bottom: 10px;
}

.NwI1WDrIyoEHBCV2RX1m {
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  background-color: inherit;
  min-width: 400px;
}

.a8lNgg4HQ2Nq60JLNZLr {
  margin-right: 8px;
}

.np0GBPeayIjaIt3cvItl {
  margin-right: 8px;
  height: 14px;
  width: 14px;
}

.rtXgpTWXhzvYl1qwjREM {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #333333;
}

.MJE94dLYsuwptdckgKCW {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #555555;
}

._cG26JX1N221IiBKJW4g {
  display: flex;
  align-items: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListContainer": `fk4mkhb1a8F7UxJ8OlE8`,
	"listGroupItem": `NwI1WDrIyoEHBCV2RX1m`,
	"checkSvg": `a8lNgg4HQ2Nq60JLNZLr`,
	"checkItemSvg": `np0GBPeayIjaIt3cvItl`,
	"checkTextHeading": `rtXgpTWXhzvYl1qwjREM`,
	"checkText": `MJE94dLYsuwptdckgKCW`,
	"listingText": `_cG26JX1N221IiBKJW4g`
};
export default ___CSS_LOADER_EXPORT___;
