/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
import { Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import DropdownMenu from './DropdownMenu';
import Overview from './Overview';
import VerficationDetails from './VerficationDetails';
import EditCandidateModal from './EditCandidateModal';
import CategoryModals from '../CategoryModals';
import Button from '../../../core-components/Button';
import Icon from '../../../core-components/Icon';
import Image from '../../../core-components/Image';
import Loader from '../../../core-components/Loader';
import { STATUSES } from '../../../utils';
import { errToastMessage, getBanners, toCamelCaseTemp } from '../../../utils/utilities';
import { getSelectedCheckDetails } from '../../../api/company';
import AppConstants from '../../../core-components/AppConstants';
import localStorage from '../../../api/localStorage';
import { bgvUrl as bgvUrlAPI } from '../../../api/company';

import styles from './ViewCandidate.module.scss';
import EditEmail from '../UtilsComponents/EditEmail';
import { FILLED_CHECKS, sexTypesObj } from '../../../utils/commonConstant';
import { capitalizeName } from '../frontend-common/utils/Utilities';
import AdminModals from '../Administrators/AdminModals';

export default class extends React.Component {
  state = {
    editModalShow: false,
    modalShow: false,
    reloadingConfig: false,
    modalTitle: null,
    modalSubTitle: null,
    modalButton: null,
    isCategoryEdit: false,
    categories: [],
    isTagEdit: false,
    tags: [],
    status: false,
    modalShowStatus: false,
    modalShowContent: '',
    modalPlaceholder: null,
    packageDetails: null,
    navItems: [
      { id: 0, icon: 'overviewIcon.svg', iconHover: 'overviewIconHover.svg', name: 'Overview' },
      {
        id: 1,
        icon: 'identityIcon.svg',
        iconHover: 'identityIconHover.svg',
        name: 'Identity',
        check: 'candidatesIds',
        checkName: 'identity'
      },
      {
        id: 3,
        icon: 'addressIcon.svg',
        iconHover: 'addressIconHover.svg',
        name: 'Address',
        check: 'candidatesAddresses',
        checkName: 'address'
      },
      {
        id: 4,
        icon: 'employmentIcon.svg',
        iconHover: 'employmentIconHover.svg',
        name: 'Employment',
        check: 'candidatesEmployments',
        checkName: 'employment'
      },
      {
        id: 5,
        icon: 'educationIcon.svg',
        iconHover: 'educationIconHover.svg',
        name: 'Education',
        check: 'candidatesEducations',
        checkName: 'education'
      },
      {
        id: 2,
        icon: 'courtRecordIcon.svg',
        iconHover: 'courtRecordIconHover.svg',
        name: 'Court',
        check: 'candidateHistories',
        checkName: 'court'
      },
      {
        id: 6,
        icon: 'worldIcon.svg',
        iconHover: 'worldIconHover.svg',
        name: 'World',
        check: 'candidatesWorldchecks',
        checkName: 'world'
      },
      {
        id: 7,
        icon: 'drugIcon.svg',
        iconHover: 'drugIconHover.svg',
        name: 'Drug',
        check: 'candidateDrugs',
        checkName: 'drug'
      },
      {
        id: 9,
        icon: 'referenceIcon.svg',
        iconHover: 'referenceIconHover.svg',
        name: 'Reference',
        check: 'candidatesRefchecks',
        checkName: 'reference'
      },
      {
        id: 8,
        icon: 'cibilCreditScoreIcon.svg',
        iconHover: 'cibilCreditScoreIconHover.svg',
        name: 'Credit Check',
        check: 'candidatesCreditchecks',
        checkName: 'cibil'
      },
      {
        id: 10,
        icon: 'socialMedia.svg',
        iconHover: 'socialMediaHover.svg',
        name: 'Social Media Check',
        check: 'candidatesSocialMedia',
        checkName: 'socialMedia'
      }
    ],
    showEditModal: false,
    activeItem: 0,
    candidateInfo: null,
    loading: false,
    isAdmin: localStorage.accessLevel === 'admin',
    errorStatus: null,
    checkNameArr: {
      identity: 1,
      address: 2,
      employment: 3,
      education: 4,
      worldcheck: 6,
      creditcheck: 9,
      referencecheck: 8,
      drugs: 7,
      History: 5,
      SocialMedia: 10
    },
    customCheckSelected: null
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCandidate();
    this.fetchCategories();
    this.fetchTags();
    if (this.props?.location?.state === 'invite') {
      this.setState({
        modalShowStatus: true,
        modalShowContent: 'BGV Link has been sent to the candidate'
      });
    } else if (this.props?.location?.state === 'candidateAdded') {
      this.setState({
        modalShowStatus: true,
        modalShowContent: 'CandidateAdded'
      });
    }
    const check = new URLSearchParams(document.location.search).get('check') || null;
    if (check !== null) {
      this.handleNavItemClick(this.state.checkNameArr[check]);
    }
    getSelectedCheckDetails(new URLSearchParams(document.location.search).get('candidateId'))
      .then((response) => {
        const allChecks = toCamelCaseTemp(response?.data?.data);
        if (allChecks?.checksIncluded?.drug === 'NONE') {
          delete allChecks?.checksIncluded?.drug;
        }
        this.setState({
          packageDetails: allChecks
        });
      })
      .catch((error) => {
        console.error(error);
        errToastMessage(error);
      });
  }

  checkCustomChecks = () => {
    const { navItems, packageDetails, candidateInfo } = this.state;
    const updatedNavItems = [...navItems];
    let navIndex = navItems.length;

    const addCustomChecks = (checks) => {
      checks?.forEach((check) => {
        if (!updatedNavItems.map((item) => item.name).includes(check?.checkName)) {
          updatedNavItems.push({
            id: navIndex,
            icon: 'customIcon.svg',
            iconHover: 'customIconHover.svg',
            name: check?.checkName || check,
            check: 'customCheck',
            checkName: 'customCheck'
          });
          navIndex++;
        }
      });
    };

    if (
      packageDetails?.checksIncluded?.custom?.length > 0 ||
      packageDetails?.addons?.custom?.length > 0
    ) {
      if (candidateInfo?.customCheck?.length === 0) {
        addCustomChecks(packageDetails.checksIncluded.custom);
      } else {
        addCustomChecks(candidateInfo?.customCheck);
      }
    }
    this.setState({
      navItems: updatedNavItems
    });
  };

  fetchCandidate = (id) => {
    const candidateId = id ? id : new URLSearchParams(document.location.search).get('candidateId');
    this.setState({ loading: true });
    return this.props?.fetchCandidateDetails(candidateId).then(
      () => {
        this.setState({
          candidateInfo: this.props?.candidateInfo,
          loading: false
        });
        this.checkCustomChecks();
      },
      (error) => {
        if (error?.response?.status !== 404) {
          errToastMessage(error);
        }
        this.setState({
          candidateInfo: null,
          loading: false,
          errorStatus: error?.response?.status
        });
      }
    );
  };

  fetchCategories = () => {
    let categoriesList = [];
    return this.props?.getCategories().then(() => {
      this.props?.categories?.userCategories &&
        this.props?.categories?.userCategories?.map((category) => {
          return categoriesList.push({ label: category.categoryName, value: category.id });
        });
      this.setState({
        categories: categoriesList
      });
    });
  };

  fetchTags = () => {
    let tagsList = [];
    return this.props?.getTags().then(() => {
      this.props?.tags &&
        this.props?.tags?.categories &&
        Array.isArray(this.props.tags.categories) &&
        this.props.tags.categories.map((tag) => {
          tagsList.push({ label: tag.category, value: tag.id });
        });
      this.setState({
        tags: tagsList
      });
    });
  };

  getSelectedTag = () => {
    let tag =
      isArray(this?.props?.candidateInfo?.candidateCompanyCategoryMappings) &&
      this?.props?.candidateInfo?.candidateCompanyCategoryMappings?.length > 0
        ? this?.props?.candidateInfo?.candidateCompanyCategoryMappings?.map((value) => {
            return { label: value?.companyCategory?.category, value: value?.companyCategory?.id };
          })
        : [];
    this.props.setFieldValue('tagId', tag);
  };

  modalToggle = (modalTitle, modalSubTitle, modalButton, placeholder) => {
    this.setState({
      modalShow: true,
      modalTitle: modalTitle,
      modalSubTitle: modalSubTitle,
      modalButton: modalButton,
      modalPlaceholder: placeholder
    });
  };

  modalHide = (value) => {
    if (value === 'reload category') {
      this.setState({
        modalShowStatus: true,
        modalShowContent: 'Category has been created successfully',
        modalShow: false
      });
      this.fetchCategories();
    } else if (value === 'reload tag') {
      this.setState({
        modalShowStatus: true,
        modalShowContent: 'Tag has been created successfully',
        modalShow: false
      });
      this.fetchTags();
    } else if (value === 'emailSent') {
      this.setState({
        modalShow: false
      });
      toast.success('Verification email has been sent');
    } else {
      this.setState({
        modalShow: false
      });
    }
    this.props.history.push({
      pathname: '/viewCandidate',
      search: `?candidateId=${new URLSearchParams(document.location.search).get('candidateId')}`
    });
  };

  modalHideStatus = () => {
    this.setState({
      modalShowStatus: false,
      modalShowContent: ''
    });
    this.props.history.push({
      pathname: '/viewCandidate',
      search: `?candidateId=${new URLSearchParams(document.location.search).get('candidateId')}`
    });
  };

  editModalToggle = () => {
    this.setState({
      editModalShow: true
    });
  };

  editModalHide = (value) => {
    if (value === 'reloadCandidate') {
      this.setState({
        editModalShow: false,
        modalShowStatus: false,
        modalShowContent: ''
      });
      this.fetchCandidate();
      this.fetchCategories();
      this.fetchTags();
    } else {
      this.setState({
        editModalShow: false
      });
    }
    this.props.history.push({
      pathname: '/viewCandidate',
      search: `?candidateId=${new URLSearchParams(document.location.search).get('candidateId')}`
    });
  };

  dropdownHandler = (status) => {
    if (status === 'resendVerification') {
      this.modalToggle(
        'Resend Verification Email?',
        'Are you sure you want to resend the verification email?',
        'Send'
      );
    } else if (status === 'editDetails') {
      this.editModalToggle();
    }
  };

  componentDidUpdate(nextProps) {
    if (
      nextProps.location.search.split('&')[0].split('=')[1] !==
      nextProps.history.location.search.split('&')[0].split('=')[1]
    ) {
      this.fetchCandidate(new URLSearchParams(document.location.search).get('candidateId'));
      this.fetchCategories();
      this.fetchTags();
    }
  }

  handleNavItemClick = (index) => {
    let isCustomCheck = this.state.navItems.filter(
      (item) => item.id == index && item.checkName == 'customCheck'
    );
    if (isCustomCheck.length > 0) {
      this.setState({ activeItem: index, customCheckSelected: index });
    } else this.setState({ activeItem: index, customCheckSelected: null });
  };

  getCustomCheckDetails = () => {
    return this.props?.candidateInfo?.customCheck.filter(
      (item) => item.checkName == this.state.navItems[this.state.customCheckSelected].name
    );
  };
  /**
   * * This function checks if the check-type is either included as an Addon or in ChecksIncluded section.
   */
  isCheckIncluded = (checkName) => {
    const { checksIncluded, addons } = this.state?.packageDetails;
    let res = false;
    switch (checkName) {
      case 'address':
      case 'identity':
      case 'reference':
      case 'custom':
      case 'court':
      case 'socialMedia':
        res = addons[checkName].length > 0 || checksIncluded[checkName].length > 0 ? true : false;
        break;
      case 'cibil':
      case 'world':
        res = addons[checkName].cost !== null || checksIncluded[checkName] ? true : false;
        break;
      case 'drug':
        res =
          addons[checkName].length > 0 ||
          ['5_PANEL', '7_PANEL', '10_PANEL'].includes(checksIncluded[checkName])
            ? true
            : false;
        break;
      case 'education':
      case 'employment':
        res =
          addons[checkName].cost ||
          checksIncluded[checkName] > 0 ||
          checksIncluded[checkName].length > 0
            ? true
            : false;
        break;
      case 'customCheck':
        res = true;
        break;
      default:
        res = false;
    }

    return res;
  };
  addAnotherCandidateHandler = () => {
    this.props.history.push('/candidate/create');
  };
  editEmailHandlerModal = (isSubmit = false) => {
    this.setState({ showEditModal: !this.state.showEditModal });
    if (isSubmit) {
      this.props?.getPaymentDetails();
      this.fetchCandidate();
    }
  };

  getEmailData = (emailData) => {
    /**
     * Note:
     * * Limit one log per day(last log for every day).
     * * Limit to last 10 logs only.
     */

    const filteredLog = new Map();

    emailData.forEach((item, index) => {
      const dateObj = new Date(item.eventTime);
      const date = dateObj.getDate();

      if (!filteredLog.has(date)) {
        filteredLog.set(date, item);
      }
    });

    let value = [];

    filteredLog.forEach((i) => {
      value.push(
        <p>
          Email last viewed on {moment(i.eventTime).utc('GMT+5:30').format('HH:mm')} on{' '}
          {moment(i.eventTime).utc('GMT+5:30').format('DD-MMM-YYYY')}
        </p>
      );
    });

    if (value.length > 10) value = value.splice(0, 10);

    return value;
  };

  render() {
    const { handleSubmit, isSubmitting, values, setFieldValue, touched } = this.props;
    const candidate = this.state.candidateInfo;
    const banners = getBanners({
      paymentDetails: this?.props?.paymentDetails,
      isCandidatePage: true
    });

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '21px',
        borderRadius: '4px',
        padding: '10px 20px',
        border: 'none',
        cursor: 'pointer',
        color: state.isSelected ? '#fff' : state.isDisabled ? '#bbb' : '#666666',
        textAlign: state.isDisabled && 'center',
        background: state.isDisabled && '#fff !important'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '200px',
        border: '1px solid #ebedf2',
        borderColor: state.selectProps.error ? '#dc3545 !important' : '#ebedf2',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        display: 'flex'
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#333333'
        };
      },
      valueContainer: (base) => ({
        ...base
      }),
      container: (base) => ({
        ...base,
        width: '200px'
      }),
      menu: (provided, state) => ({
        ...provided,
        zIndex: '10'
      })
    };

    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={{ display: 'none' }} {...innerProps} />;
    };

    const getCategoryNameId = (key = 'categoryName', category = null) => {
      return isArray(category) && category.length
        ? category[0].userCategory[key]
        : category && category.userCategory
        ? category.userCategory[key]
        : 'None';
    };

    let selectedType = null;
    let i = 0;

    if (candidate?.companyCandidateMapping?.status === -1) {
      candidate.companyCandidateMapping.status = 7;
    }

    const showDeletedUser = (data) => {
      return <span className={data?.deletedAt ? `${styles.deletedUser}` : null}>{data.name}</span>;
    };

    const handleReloadTags = async () => {
      this.setState({ reloadingConfig: true });
      await this.fetchTags();
      this.setState({ reloadingConfig: false });
    };

    const handleReloadCategories = async () => {
      this.setState({ reloadingConfig: true });
      await this.fetchCategories();
      this.setState({ reloadingConfig: false });
    };

    const handleOnChangeTags = (selectedTags = []) => {
      this.props.setSubmitting(false);
      setFieldValue('tagId', selectedTags);
      if (values.tagsError && selectedTags?.length) {
        const matchingNames = selectedTags
          .map((tag) => tag.label?.toLowerCase())
          .filter((name) => values.tagsError.toLowerCase().includes(name));

        if (matchingNames.length <= 0) setFieldValue('tagsError', '');
      } else {
        setFieldValue('tagsError', '');
      }
    };

    const handleOnClickViewReport = () => {
      window.open(
        `${AppConstants.baseURL}v1/candidate/report/html?candidate_id=${candidate.id}`,
        '_blank'
      );
    };

    return (
      <div>
        <CategoryModals
          show={this.state.modalShow}
          onHide={this.modalHide}
          title={this.state.modalTitle}
          subTitle={this.state.modalSubTitle}
          button={this.state.modalButton}
          candidate={candidate}
          placeholder={this.state.modalPlaceholder}
        />
        <EditEmail
          show={this.state.showEditModal}
          onHide={this.editEmailHandlerModal}
          email={candidate?.email}
          candidateId={candidate?.id}
        />
        <AdminModals
          show={this.state.modalShowStatus}
          onHide={this.modalHideStatus}
          banners={banners}
          content={this.state.modalShowContent}
          addAnotherCandidateHandler={this.addAnotherCandidateHandler}
        />
        {candidate !== null && !this.state.loading ? (
          <div className={styles.viewCandidateWrapper}>
            <div className={styles.displayNone}>
              {candidate?.companyCandidateMapping?.candidateForm &&
              candidate?.companyCandidateMapping?.candidateForm?.checkType
                ? (selectedType = candidate?.companyCandidateMapping?.candidateForm?.checkType
                    .split(',')
                    .filter((item, ind, ar) => ar.indexOf(item) === ind)
                    .sort())
                : ''}
            </div>
            <Card className={styles.viewCandidateCard}>
              <EditCandidateModal
                modalShow={this.state.editModalShow}
                onModalHide={this.editModalHide}
                candidate={candidate}
                {...this.props}
              />
              <div className={styles.candidateInfoCard}>
                <div className={styles.infoHeader}>
                  <div className={styles.infoHeaderLeft}>
                    <div className={styles.info}>
                      <div className={styles.candidateName}>{capitalizeName(candidate.name)}</div>
                      {candidate?.companyCandidateMapping?.resumeFormat !== null ? (
                        <div className={styles.resumeLink}>
                          <a
                            href={
                              candidate?.companyCandidateMapping?.resumeFormat === 'DOCX'
                                ? `${AppConstants.developmentAPIURL}auth/docs?company_id=${
                                    candidate?.companyCandidateMapping?.companyId
                                  }&candidate_id=${
                                    candidate?.companyCandidateMapping?.candidateId
                                  }&type=resume&token=${localStorage.getToken()}`
                                : `${AppConstants.baseURL}candidate/resume?candidate_id=${candidate?.companyCandidateMapping?.candidateId}`
                            }
                            target={'_blank'}
                          >
                            {`View Resume (${candidate?.companyCandidateMapping?.resumeFormat})`}
                          </a>
                        </div>
                      ) : (
                        <div></div>
                      )}
                      {candidate?.companyCandidateMapping?.consentLetterUrl ? (
                        <div className={styles.resumeLink}>
                          <a
                            href={
                              candidate?.companyCandidateMapping?.consentLetterUrl.includes(
                                'csv'
                              ) ||
                              candidate?.companyCandidateMapping?.consentLetterUrl.includes(
                                'xls'
                              ) ||
                              candidate?.companyCandidateMapping?.consentLetterUrl.includes(
                                'docx'
                              ) ||
                              candidate?.companyCandidateMapping?.consentLetterUrl.includes('xlsx')
                                ? `${AppConstants.developmentAPIURL}auth/docs?company_id=${
                                    candidate?.companyCandidateMapping?.companyId
                                  }&candidate_id=${
                                    candidate?.companyCandidateMapping?.candidateId
                                  }&type=consent&token=${localStorage.getToken()}`
                                : `${AppConstants.baseURL}candidate/consent-letter?candidate_id=${candidate?.companyCandidateMapping?.candidateId}`
                            }
                            target='_blank'
                          >
                            {candidate?.companyCandidateMapping?.consentType === 'CONSENT_LETTER'
                              ? 'Consent Letter'
                              : 'Consent Undertaking'}
                          </a>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                    <div
                      className={styles.status}
                      style={{
                        color: STATUSES.values[candidate?.companyCandidateMapping?.status]?.color
                      }}
                    >
                      <svg
                        width='8'
                        height='8'
                        viewBox='0 0 8 8'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <circle
                          cx='4'
                          cy='4'
                          r='4'
                          fill={STATUSES.values[candidate?.companyCandidateMapping?.status]?.color}
                        />
                      </svg>
                      {STATUSES.values[candidate?.companyCandidateMapping?.status]?.value}
                      <div className={styles.overlayTooltip}>
                        {candidate?.companyCandidateMapping?.status === 3 &&
                        candidate.emailTrackingLogs &&
                        candidate.emailTrackingLogs.length &&
                        candidate.emailRejectType === 'NA' ? (
                          <OverlayTrigger
                            placement='bottom'
                            overlay={
                              <Tooltip id={'emailData'}>
                                {this.getEmailData(candidate.emailTrackingLogs)}
                              </Tooltip>
                            }
                          >
                            <div className={styles.mailIcon}>
                              <FontAwesomeIcon
                                icon={faEye}
                                color={'#366cf3'}
                                style={{ marginRight: '8px' }}
                              />
                            </div>
                          </OverlayTrigger>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className={styles.infoHeaderRight}>
                    {candidate.reportUrl !== null && candidate.type !== 1 ? (
                      <Button onClick={handleOnClickViewReport} className={styles.viewReportBtn}>
                        {'View Report'}
                      </Button>
                    ) : (
                      <div></div>
                    )}
                    <div className={styles.optionBtn}>
                      <DropdownMenu
                        {...this.props}
                        onSelect={(status) => this.dropdownHandler(status)}
                        status={candidate?.companyCandidateMapping?.status}
                      />
                    </div>
                  </div>
                </div>

                <div className={styles.infoContent}>
                  <div className={styles.basicInfo}>
                    <div>
                      <div className={styles.infoCard}>
                        <div className={styles.infoLabel}>{'SVID'}</div>
                        <div className={styles.infoValue}>
                          {candidate?.companyCandidateMapping?.candidateId
                            ? candidate?.companyCandidateMapping?.candidateId
                            : '-'}
                        </div>
                      </div>
                      <div className={styles.infoCard}>
                        <div className={styles.infoLabel}>{'Sex'}</div>
                        <div className={styles.infoValue}>
                          {candidate?.gender ? sexTypesObj[candidate?.gender] : '-'}
                        </div>
                      </div>
                      <div className={styles.infoCard}>
                        <div className={styles.infoLabel}>{'Phone'}</div>
                        <div className={styles.infoValue}>
                          {candidate?.companyCandidateMapping?.phoneNumber
                            ? candidate?.companyCandidateMapping?.phoneNumber[0] !== '+'
                              ? `+91${candidate?.companyCandidateMapping?.phoneNumber}`
                              : candidate?.companyCandidateMapping?.phoneNumber
                            : '-'}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className={styles.infoCard}>
                        <div className={styles.infoLabel}>{'Emp ID'}</div>
                        <div className={styles.infoValue}>
                          {candidate?.companyCandidateMapping?.employeeId
                            ? candidate?.companyCandidateMapping?.employeeId
                            : '-'}
                        </div>
                      </div>
                      <div className={styles.infoCard}>
                        <div className={styles.infoLabel}>{'UAN'}</div>
                        <div className={styles.infoValue}>
                          {candidate?.companyCandidateMapping?.uanNumber
                            ? candidate?.companyCandidateMapping?.uanNumber
                            : '-'}
                        </div>
                      </div>
                      <div className={styles.emailAddressInfo}>
                        <div className={styles.infoLabel}>
                          {'Email  '}
                          {candidate.emailRejectType === 'NA' ||
                          isEmpty(candidate?.email) ? null : (
                            <OverlayTrigger
                              placement='bottom'
                              overlay={
                                <Tooltip id={'EmailBounced'}>
                                  <p>{'Email has bounced'}</p>
                                </Tooltip>
                              }
                            >
                              <span className={styles.mailIcon}>
                                <Image name='emailBouncedIcon.svg' />
                              </span>
                            </OverlayTrigger>
                          )}
                        </div>
                        <div className={styles.infoValue}>
                          {candidate.email ? candidate.email : '-'}
                          {/* TO_DO Add condition for conditional rendering of email edit */}
                          {candidate.emailRejectType === 'NA'
                            ? null
                            : (candidate?.companyCandidateMapping?.status == 3 ||
                                candidate?.companyCandidateMapping?.status == 11) && (
                                <div
                                  className={styles.editCategoryIcon}
                                  onClick={() => {
                                    this.editEmailHandlerModal();
                                  }}
                                >
                                  <Icon
                                    icon={'EditCandidateCategory'}
                                    color={'#333333'}
                                    height={16}
                                    width={16}
                                  />
                                </div>
                              )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.categoryInfo}>
                    <div className={styles.infoCard}>
                      <div className={styles.infoLabel}>{'Category'}</div>
                      {values.categoryEdited ? (
                        <Form onSubmit={handleSubmit}>
                          <div className={styles.editCategoryContainer}>
                            <div className={styles.categoryDropdown + ' mb-2'}>
                              <Select
                                isClearable={true}
                                placeholder='None'
                                isLoading={this.state.reloadingConfig}
                                options={
                                  this.state.reloadingConfig
                                    ? [{ value: '', label: 'Loading...', isDisabled: true }]
                                    : this.state.categories
                                }
                                onMenuOpen={handleReloadCategories}
                                noOptionsMessage={() => 'No Categories found'}
                                styles={customStyles}
                                value={this.state.categories.find(
                                  (c) => c.value === values?.categoryId
                                )}
                                error={values?.categoryError}
                                onChange={(value) => {
                                  this.props.setSubmitting(false);
                                  setFieldValue('categoryId', value?.value || '');
                                  setFieldValue('categoryError', '');
                                }}
                              />
                              {this.state.isAdmin ? (
                                <div
                                  className={styles.addIcon}
                                  onClick={() => {
                                    this.modalToggle(
                                      'Create Category',
                                      'Name',
                                      'Create',
                                      'Category name'
                                    );
                                  }}
                                >
                                  {'+'}
                                </div>
                              ) : (
                                <>{null}</>
                              )}
                            </div>
                            <div className={styles.editCategoryBtns}>
                              <Button
                                className={styles.saveBtn}
                                type='submit'
                                disabled={isSubmitting || values?.categoryError}
                                onClick={() => {
                                  setFieldValue('edit', 'category');
                                }}
                              >
                                {'Save'}
                              </Button>
                              <div
                                className={styles.cancelBtn}
                                onClick={() => setFieldValue('categoryEdited', false)}
                              >
                                {'X'}
                              </div>
                            </div>
                          </div>
                          {values?.categoryError && (
                            <div className={styles.dpErrorMsg}>{values?.categoryError}</div>
                          )}
                        </Form>
                      ) : (
                        <div className={styles.infoValue}>
                          {getCategoryNameId(
                            'categoryName',
                            candidate.candidateUserCategoryMapping
                          )}

                          <div
                            className={styles.editCategoryIcon}
                            onClick={() => {
                              setFieldValue(
                                'categoryId',
                                getCategoryNameId('id', candidate.candidateUserCategoryMapping)
                              );
                              setFieldValue('categoryEdited', true);
                            }}
                          >
                            <Icon
                              icon={'EditCandidateCategory'}
                              color={'#333333'}
                              height={16}
                              width={16}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.infoCard}>
                      <div className={styles.infoLabel}>{'Tags'}</div>
                      {values.tagEdited ? (
                        <Form onSubmit={handleSubmit}>
                          <div className={styles.editCategoryContainer}>
                            <div className={styles.categoryDropdown}>
                              <Select
                                isMulti
                                isClearable={true}
                                placeholder='None'
                                name='tagId'
                                components={{ IndicatorSeparator }}
                                isLoading={this.state.reloadingConfig}
                                options={
                                  this.state.reloadingConfig
                                    ? [{ value: '', label: 'Loading...', isDisabled: true }]
                                    : this.state.tags
                                }
                                onMenuOpen={handleReloadTags}
                                noOptionsMessage={() => 'No Tags found'}
                                styles={customStyles}
                                closeMenuOnSelect={false}
                                value={values.tagId}
                                error={values.tagsError}
                                onChange={handleOnChangeTags}
                              />
                              {this.state.isAdmin ? (
                                <div
                                  className={styles.addIcon}
                                  onClick={() => {
                                    this.modalToggle('Create Tag', 'Name', 'Create', 'Tag name');
                                  }}
                                >
                                  {'+'}
                                </div>
                              ) : null}
                            </div>
                            <div className={styles.editCategoryBtns}>
                              <Button
                                className={styles.saveBtn}
                                type='submit'
                                disabled={isSubmitting || values?.tagsError}
                                onClick={() => setFieldValue('edit', 'tag')}
                              >
                                {'Save'}
                              </Button>
                              <div
                                className={styles.cancelBtn}
                                onClick={() => setFieldValue('tagEdited', false)}
                              >
                                {'X'}
                              </div>
                            </div>
                          </div>
                          {values?.tagsError && (
                            <div className={styles.dpErrorMsg}>{values?.tagsError}</div>
                          )}
                        </Form>
                      ) : (
                        <div className={styles.infoValue}>
                          <div className={styles.tagWrapper} style={{ width: 'min-content' }}>
                            {candidate?.candidateCompanyCategoryMappings?.length > 0
                              ? candidate?.candidateCompanyCategoryMappings.map((tag) => {
                                  return (
                                    <div className={styles.tag}>{tag.companyCategory.category}</div>
                                  );
                                })
                              : 'None'}
                          </div>
                          <div
                            className={styles.editCategoryIcon}
                            onClick={() => {
                              this.getSelectedTag();
                              setFieldValue('tagEdited', true);
                            }}
                          >
                            <Icon
                              icon={'EditCandidateCategory'}
                              color={'#333333'}
                              height={16}
                              width={16}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={styles.infoCard}>
                      <div className={styles.infoLabel}>{'DOJ'}</div>
                      <div className={styles.infoValue}>
                        {candidate?.doj && moment(candidate.doj).isValid()
                          ? moment(candidate?.doj).format('DD-MM-YYYY')
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <div className={styles.moreInfo}>
                    <div className={styles.timelineSingle}>
                      <div className={styles.tlProgress}>
                        <Image style={{ padding: '10px' }} name='timeLineDotFilled.svg' />
                        {candidate?.companyCandidateMapping?.consentAddedBy &&
                        (candidate?.companyCandidateMapping?.consentAddedBy === 1 ||
                          candidate?.companyCandidateMapping?.consentAddedBy === 2) ? (
                          <Image
                            name={
                              candidate.companyCandidateMapping.consentAddedAt
                                ? 'timeLineVectorFilled.svg'
                                : 'timeLineVectorEmpty.svg'
                            }
                          />
                        ) : (
                          <Image
                            name={
                              candidate?.companyCandidateMapping?.formFilled
                                ? 'timeLineVectorFilled.svg'
                                : 'timeLineVectorEmpty.svg'
                            }
                          />
                        )}
                      </div>
                      <div className={styles.tlContent}>
                        <div className={styles.tlContentHeading}>Candidate details added</div>
                        <div className={styles.tlContentSub}>
                          {' '}
                          By{' '}
                          {candidate?.companyCandidateMapping?.candidateAddedBy === 1 ? (
                            'SpringVerify'
                          ) : candidate?.companyCandidateMapping?.candidateAddedBy === 5 ? (
                            <span
                              className={
                                candidate?.companyApiToken?.deletedAt
                                  ? `${styles.deletedUser}`
                                  : null
                              }
                            >
                              {`API (${candidate?.companyApiToken?.name})`}
                            </span>
                          ) : candidate?.companyCandidateMapping?.candidateAddedBy === 2 ? (
                            candidate?.user?.name ? (
                              <span
                                className={
                                  candidate.user.deletedByUserId ? `${styles.deletedUser}` : null
                                }
                              >
                                {candidate.user.name}
                              </span>
                            ) : (
                              <span className={styles.deletedUser}>Company Admin</span>
                            )
                          ) : (
                            'N/A'
                          )}{' '}
                          on {moment(candidate.createdAt).utc('GMT+5:30').format('DD-MMM-YYYY')} at{' '}
                          {moment(candidate.createdAt).utc('GMT+5:30').format('HH:mm')}
                        </div>
                      </div>
                    </div>
                    {/* when consent filled by CS/SA */}
                    {candidate?.companyCandidateMapping?.consentAddedBy &&
                    (candidate?.companyCandidateMapping?.consentAddedBy === 1 ||
                      candidate?.companyCandidateMapping?.consentAddedBy === 2 ||
                      candidate?.companyCandidateMapping?.consentAddedBy === 5) ? (
                      <>
                        <div className={styles.timelineSingle}>
                          <div className={styles.tlProgress}>
                            <Image
                              style={{ padding: '10px' }}
                              name={
                                candidate.companyCandidateMapping.consentAddedAt
                                  ? 'timeLineDotFilled.svg'
                                  : 'timeLineDotEmpty.svg'
                              }
                            />
                            <Image
                              name={
                                candidate.companyCandidateMapping.formFilled
                                  ? 'timeLineVectorFilled.svg'
                                  : 'timeLineVectorEmpty.svg'
                              }
                            />
                          </div>
                          <div className={styles.tlContent}>
                            <div className={styles.tlContentHeading}>Consent form</div>
                            <div className={styles.tlContentSub}>
                              {candidate?.companyCandidateMapping?.consentAddedAt ? (
                                <div>
                                  By{' '}
                                  {candidate?.companyCandidateMapping?.consentAddedBy === 5
                                    ? `API(${candidate?.companyApiToken?.name})`
                                    : candidate?.companyCandidateMapping?.consentAddedBy === 1
                                    ? 'SpringVerify'
                                    : candidate?.companyCandidateMapping?.consentAddedBy === 2
                                    ? candidate?.companyCandidateMapping?.consentAddedByUser?.name
                                      ? showDeletedUser(
                                          candidate?.companyCandidateMapping?.consentAddedByUser
                                        )
                                      : 'Company Admin'
                                    : 'N/A'}{' '}
                                  on{' '}
                                  {moment(candidate?.companyCandidateMapping?.consentAddedAt)
                                    .utc('GMT+5:30')
                                    .format('DD-MMM-YYYY')}{' '}
                                  at{' '}
                                  {moment(candidate?.companyCandidateMapping?.consentAddedAt)
                                    .utc('GMT+5:30')
                                    .format('HH:mm')}
                                </div>
                              ) : (
                                'Not submitted yet'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.timelineSingle}>
                          <div className={styles.tlProgress}>
                            <Image
                              style={{ padding: '10px' }}
                              name={
                                candidate.companyCandidateMapping.formFilled
                                  ? 'timeLineDotFilled.svg'
                                  : 'timeLineDotEmpty.svg'
                              }
                            />
                          </div>
                          <div className={styles.tlContent}>
                            <div className={styles.tlContentHeading}>BGV form</div>
                            <div className={styles.tlContentSub}>
                              {candidate?.companyCandidateMapping?.formFilled ? (
                                <div>
                                  By{' '}
                                  {candidate?.companyCandidateMapping?.formFilledBy === 1 ||
                                  candidate?.companyCandidateMapping?.formFilledBy === 2
                                    ? candidate?.companyCandidateMapping?.formFilledBy === 1
                                      ? 'SpringVerify'
                                      : candidate?.companyCandidateMapping?.formFilledBy === 2
                                      ? candidate?.companyCandidateMapping?.formFilledByUser?.name
                                        ? showDeletedUser(
                                            candidate?.companyCandidateMapping?.formFilledByUser
                                          )
                                        : 'Company Admin'
                                      : 'N/A'
                                    : 'candidate'}{' '}
                                  on{' '}
                                  {moment(candidate.companyCandidateMapping.formFilled)
                                    .utc('GMT+5:30')
                                    .format('DD-MMM-YYYY')}{' '}
                                  at{' '}
                                  {moment(candidate.companyCandidateMapping.formFilled)
                                    .utc('GMT+5:30')
                                    .format('HH:mm')}
                                </div>
                              ) : (
                                'Not submitted yet'
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* consent filled by candidate  */}
                        <div className={styles.timelineSingle}>
                          <div className={styles.tlProgress}>
                            <Image
                              style={{ padding: '10px' }}
                              name={
                                candidate?.companyCandidateMapping?.formFilled
                                  ? 'timeLineDotFilled.svg'
                                  : 'timeLineDotEmpty.svg'
                              }
                            />
                            <Image
                              name={
                                candidate?.companyCandidateMapping?.consentAddedAt
                                  ? 'timeLineVectorFilled.svg'
                                  : 'timeLineVectorEmpty.svg'
                              }
                            />
                          </div>
                          <div className={styles.tlContent}>
                            <div className={styles.tlContentHeading}>BGV form</div>
                            <div className={styles.tlContentSub}>
                              {candidate?.companyCandidateMapping?.formFilled ? (
                                <div>
                                  By{' '}
                                  {candidate?.companyCandidateMapping?.formFilledBy === 1 ||
                                  candidate?.companyCandidateMapping?.formFilledBy === 2
                                    ? candidate?.companyCandidateMapping?.formFilledBy === 1
                                      ? 'SpringVerify'
                                      : candidate?.companyCandidateMapping?.formFilledBy === 2
                                      ? candidate?.companyCandidateMapping?.formFilledByUser?.name
                                        ? showDeletedUser(
                                            candidate?.companyCandidateMapping?.formFilledByUser
                                          )
                                        : 'Company Admin'
                                      : 'N/A'
                                    : 'candidate'}{' '}
                                  on{' '}
                                  {moment(candidate?.companyCandidateMapping?.formFilled)
                                    .utc('GMT+5:30')
                                    .format('DD-MMM-YYYY')}{' '}
                                  at{' '}
                                  {moment(candidate?.companyCandidateMapping?.formFilled)
                                    .utc('GMT+5:30')
                                    .format('HH:mm')}
                                </div>
                              ) : (
                                'Not submitted yet'
                              )}
                            </div>
                          </div>
                        </div>
                        <div className={styles.timelineSingle}>
                          <div className={styles.tlProgress}>
                            <Image
                              style={{ padding: '10px' }}
                              name={
                                candidate?.companyCandidateMapping?.consentAddedAt
                                  ? 'timeLineDotFilled.svg'
                                  : 'timeLineDotEmpty.svg'
                              }
                            />
                          </div>
                          <div className={styles.tlContent}>
                            <div className={styles.tlContentHeading}>Consent form</div>
                            <div className={styles.tlContentSub}>
                              {candidate?.companyCandidateMapping?.consentAddedAt
                                ? `By candidate on ${moment(
                                    candidate?.companyCandidateMapping?.consentAddedAt
                                  )
                                    .utc('GMT+5:30')
                                    .format('DD-MMM-YYYY')} at ${moment(
                                    candidate?.companyCandidateMapping?.consentAddedAt
                                  )
                                    .utc('GMT+5:30')
                                    .format('HH:mm')}`
                                : 'Not submitted yet'}
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {candidate.type !== 1 ? (
                <div className={styles.candidateVerificationCard}>
                  <div className={styles.verificationNavbar}>
                    {this.state.navItems.map((item, index) => {
                      if (
                        index < 1 ||
                        (selectedType && selectedType.includes(item.id.toString())) ||
                        (this.state.packageDetails?.checksIncluded &&
                          this.isCheckIncluded(item.checkName)) ||
                        (candidate && candidate[FILLED_CHECKS[item.checkName]]?.length > 0)
                      ) {
                        i++;
                        return (
                          <div
                            className={`${styles.navItem} ${
                              this.state.activeItem === index ? styles.navItemActive : ''
                            }`}
                            onClick={() => {
                              this.handleNavItemClick(index);
                            }}
                          >
                            <Image
                              name={this.state.activeItem === index ? item.iconHover : item.icon}
                            />
                            {item.name}
                          </div>
                        );
                      }
                    })}
                  </div>
                  <div className={styles.verificationContent}>
                    {this.state.activeItem === 0 ? (
                      <Overview
                        candidate={candidate}
                        packageDetails={this.state.packageDetails}
                        companyCustomizations={
                          this?.props?.paymentDetails.companyCustomizations?.length
                            ? this?.props?.paymentDetails.companyCustomizations
                            : []
                        }
                      />
                    ) : (
                      <VerficationDetails
                        companyCustomizations={
                          this?.props?.paymentDetails.companyCustomizations?.length
                            ? this?.props?.paymentDetails.companyCustomizations
                            : []
                        }
                        title={this.state.navItems[this.state.activeItem].name}
                        icon={this.state.navItems[this.state.activeItem].iconHover}
                        activeItem={this.state.navItems[this.state.activeItem]}
                        verificationInfo={
                          this.state.customCheckSelected
                            ? this.getCustomCheckDetails()
                            : candidate[this.state.navItems[this.state.activeItem].check]
                        }
                        additionalInfo={candidate['candidatesAddresses']}
                        packageDetails={this?.state?.packageDetails}
                        tatData={candidate['tatData']}
                        fetchCandidate={this.fetchCandidate}
                        overallStatus={candidate?.companyCandidateMapping?.status}
                        isCustomCheck={!!this.state.customCheckSelected}
                      />
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.olderDashboard}>
                  <div className={styles.olderDashboardText}>Please check the V1 dashboard</div>
                  <div>
                    You can access the V1 dashboard through this
                    <a
                      href={
                        this?.props?.profile?.profile.company_user_mapping?.company
                          ?.dashboard_url !== null
                          ? this.props?.profile?.profile?.company_user_mapping?.company
                              ?.dashboard_url
                          : '#'
                      }
                      className={styles.olderDashboardLink}
                      target='_blank'
                    >
                      link
                    </a>
                  </div>
                </div>
              )}
            </Card>
          </div>
        ) : this.state.modalShowStatus ? null : this.state.loading ? (
          <Card className={styles.loadingviewCandidateCard}>
            <Loader className={styles.viewCandidateLoader} />
          </Card>
        ) : (
          <Card className={styles.candidateNotFoundCard}>
            {this.state.errorStatus && this.state.errorStatus !== 404 ? null : (
              <>
                <div className={styles.notFoundTitle}>{'Candidate Not Found'}</div>
                <div
                  className={styles.homeLink}
                  onClick={() => this.props.history.push('/dashboard')}
                >
                  {'Go to Home Page'}
                </div>
              </>
            )}
          </Card>
        )}
      </div>
    );
  }
}
