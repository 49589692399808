import React, { useState, useEffect } from 'react';

import { isArray } from 'lodash';
import { Accordion, Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import Image from '../../../../../core-components/Image';
import BooleanField from './Verifications/BooleanField';
import Identity from './Verifications/Identity';
import Address from './Verifications/Address';
import Court from './Verifications/Court';
import Education from './Verifications/Education';
import Employment from './Verifications/Employment';
import SocialMedia from './Verifications/SocialMedia/SocialMedia';
import Reference from './Verifications/Reference';
import { isEmpty } from 'lodash';
import styles from './Addons.module.scss';
import { CHECKS_LIMIT } from '../../../../../utils/commonConstant';
import {
  getTotalCheckCount,
  convertToIndianNumeration,
  filterObjectByKeyCaseInsensitive
} from '../../../../../utils/utilities';

export default (props) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const { values, setFieldValue, pDetails, packageType, createCandidateConfig } = props;
  const [pConfig, setPConfig] = useState(pDetails?.config);
  const [disableD5, setDisableD5] = useState(false);
  const [disableD7, setDisableD7] = useState(false);
  const [disableD10, setDisableD10] = useState(false);
  const paymentModelType =
    props?.profile?.company_user_mapping?.company?.payments_company_model_config?.model_type ===
    'POSTPAID_CANDIDATE_COMPLETION'
      ? 'POSTPAID'
      : 'PREPAID';
  const accessLevel = props?.profile?.access_level;

  useEffect(() => {
    if (pDetails?.config) {
      setPConfig(pDetails.config);
    }
  }, [pDetails]);

  /**
   * using this use effect as to check and disable drus checkbox based on saved values when cgoing back and comin on addons page
   */
  useEffect(() => {
    let type = '',
      value = false;
    if (values?.drug5Panel) {
      type = 'drug5Panel';
      value = true;
    } else if (values?.drug7Panel) {
      type = 'drug7Panel';
      value = true;
    } else if (values?.drug10Panel) {
      type = 'drug10Panel';
      value = true;
    }
    handleDrugAddonChange(type, value);
    handleConfigChange(type, null, value);
  }, [values?.drug5Panel, values?.drug7Panel, values?.drug10Panel]);

  const handleDrugAddonChange = (type, value) => {
    switch (type) {
      case 'drug5Panel':
        setDisableD7(value);
        setDisableD10(value);
        break;
      case 'drug7Panel':
        setDisableD5(value);
        setDisableD10(value);
        break;
      case 'drug10Panel':
        setDisableD5(value);
        setDisableD7(value);
        break;
    }
  };

  const decoratedOnClick = (eventKey = 0) => {
    if (eventKey === activeAccordion) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(eventKey);
    }
  };

  const renderAlertInfo = () => {
    return (
      <div className={styles.alertContainer}>
        <Alert variant={'info'} className={styles.alert}>
          <span>
            <FontAwesomeIcon icon={faInfoCircle} color={'#1890FF'} style={{ marginRight: '8px' }} />
          </span>
          <p>
            {'The add-ons will be charged using the credit balance of '}
            <b>
              <span className={styles.rupee}>&#8377;</span>
              {props.createCandidateConfig &&
                props.createCandidateConfig.credits &&
                props.createCandidateConfig.credits.paymentsCompanyCreditBalance &&
                convertToIndianNumeration(
                  props.createCandidateConfig.credits.paymentsCompanyCreditBalance.creditBalance
                )}
            </b>
          </p>
        </Alert>
      </div>
    );
  };

  const handleConfigChange = (type, name, value) => {
    let valuesCopy = { ...values };
    if (name) {
      valuesCopy[type][name] = value;
    } else {
      valuesCopy[type] = value;
    }
    setFieldValue(valuesCopy);
  };

  const handleMultipleEduConfigValueChange = (type, name, value) => {
    const valuesCopy = { ...values };
    if (name) {
      valuesCopy[type] = { ...value };
    } else {
      valuesCopy[type] = { value };
    }
    setFieldValue(valuesCopy);
  };

  const handleMultipleConfigValueChange = (type, name, value) => {
    const valuesCopy = JSON.parse(JSON.stringify(values));
    if (name) {
      valuesCopy[type][name] = value;
    } else {
      valuesCopy[type] = value;
    }
    setFieldValue(valuesCopy);
  };

  const disableAddon = (includedCheckCountInPkg, addonType) => {
    if (CHECKS_LIMIT[addonType] <= includedCheckCountInPkg) return true;
    return false;
  };

  const checkTypeAndAddonConfig = (addonType) => {
    //Get ADDONS for specific check only
    const addonConfigured = filterObjectByKeyCaseInsensitive(
      props?.createCandidateConfig?.addons,
      addonType
    );
    if (isEmpty(addonConfigured)) {
      return false;
    }

    if (pConfig[addonType].length == 1) {
      let typeIncluded = pConfig[addonType][0]?.verificationType || '';

      //Adding Prefix so that we can match it with the addons keys
      addonType === 'court'
        ? (typeIncluded = 'COURT_' + typeIncluded)
        : (typeIncluded = 'ADDRESS_' + typeIncluded);

      if (
        //To check if any method says POSTAL, company addons are configured or not
        // Refer https://docs.google.com/spreadsheets/d/1lHj_a05GVGz-kqLun2ufeODwyiTETGf5k3cRWqBvD4o/edit#gid=0
        !Object.keys(addonConfigured).some(
          (ele) => ele.toLowerCase() == typeIncluded.replace(/_/g, '').toLowerCase()
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const showAddonForCrtAndAdd = (includedCheckCountInPkg, addonType) => {
    if (
      CHECKS_LIMIT[addonType] > includedCheckCountInPkg &&
      pConfig &&
      checkTypeAndAddonConfig(addonType == 'ADD' ? 'address' : 'court')
    ) {
      return true;
    }
    return false;
  };

  const IDCount =
    createCandidateConfig.includeAadhaarInIdCheck === 1 ? CHECKS_LIMIT.ID : CHECKS_LIMIT.ID - 1;

  const validateEducation = (education) => {
    if (education === null) return true;
    else if (typeof education === 'number') {
      if (education === 0) return true;
      return !disableAddon(education, 'EDU');
    } else if (Array.isArray(education)) {
      return !disableAddon(education?.length || 0);
    }
  };

  // Calculate total package reference count from pConfig
  const calculatePackageReferenceCount = (pConfig) =>
    pConfig?.reference?.reduce((sum, count) => sum + count, 0) ?? 0;

  return (
    <div className={styles.addonsContainer}>
      <Accordion>
        <Card className={styles.addonsContainerCard}>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            className={styles.addonsContainerHeader}
            onClick={() => decoratedOnClick(0)}
          >
            <span>{'Add-ons'}</span>
            {activeAccordion === 0 ? (
              <Image name={'UpAngle.svg'} svg={'UpAngle.svg'} />
            ) : (
              <Image name={'DownAngle.svg'} svg={'DownAngle.svg'} />
            )}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className={styles.cardBody}>
              {/* Alert */}
              {packageType === 'PREPAID' &&
                props.createCandidateConfig?.credits?.paymentsCompanyCreditBalance?.creditBalance >
                  0 &&
                renderAlertInfo()}

              {/* Identity addon */}
              {props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.identity &&
              !props.pDetails?.config?.identity?.includes('ALL') ? (
                <Identity
                  heading={'Identity'}
                  price={props.createCandidateConfig.addons.identity.price}
                  pDetails={(pDetails && pDetails.config.identity) || null}
                  addonDetails={props.createCandidateConfig.addons.identity || null}
                  isAadhaarAllowed={
                    createCandidateConfig.includeAadhaarInIdCheck === 1 ? true : false
                  }
                  limit={IDCount - getTotalCheckCount(pDetails?.config?.identity)}
                  values={values.identity}
                  onValueChange={(name, value) => {
                    handleConfigChange('identity', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Address addon */}
              {props.createCandidateConfig &&
              showAddonForCrtAndAdd(pConfig ? pConfig.address?.length : 0, 'ADD') ? (
                <Address
                  addCheckCount={pConfig ? pConfig.address?.length : 0}
                  heading={'Address'}
                  pDetails={(pDetails && pDetails.config.address) || null}
                  addonDetails={
                    filterObjectByKeyCaseInsensitive(
                      props.createCandidateConfig.addons,
                      'address'
                    ) || {}
                  }
                  values={values.address}
                  onValueChange={(name, value) => {
                    handleConfigChange('address', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Court addon */}
              {props.createCandidateConfig &&
              showAddonForCrtAndAdd(pConfig ? pConfig.court?.length : 0, 'COURT') &&
              pConfig?.court &&
              pConfig.court[0] !== 'ANY_2' ? (
                <Court
                  courtCheckCount={pConfig ? pConfig.court?.length : 0}
                  heading={'Court record'}
                  pDetails={(pDetails && pDetails.config.court) || null}
                  addonDetails={
                    filterObjectByKeyCaseInsensitive(props.createCandidateConfig.addons, 'court') ||
                    {}
                  }
                  values={values.court}
                  onValueChange={(name, value) => {
                    handleConfigChange('court', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Education OFFICIAL addon */}
              {pDetails &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.education &&
              pDetails.config.educationType === 'OFFICIAL' &&
              pDetails.config.education &&
              !disableAddon(pConfig ? pConfig.education?.length : 0, 'EDU') ? (
                <Education
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  showAllEduTypes={!pDetails.config.education}
                  heading={
                    pDetails.config.education && isArray(pDetails.config.education)
                      ? 'Education'
                      : 'Education (highest)'
                  }
                  isSpecific={
                    pDetails && pDetails.config.education
                      ? isArray(pDetails.config.education)
                        ? true
                        : false
                      : false
                  }
                  pDetails={pDetails}
                  addonDetails={props.createCandidateConfig.addons.education || null}
                  price={props.createCandidateConfig.addons.education.price}
                  values={values.educationOfficial}
                  onValueChange={(name, value) => {
                    handleMultipleConfigValueChange('educationOfficial', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Education HYBRID addon */}
              {pDetails &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.educationHybrid &&
              pDetails.config.educationType === 'HYBRID' &&
              pDetails.config.education &&
              !disableAddon(pConfig ? pConfig.education?.length : 0, 'EDU') ? (
                <Education
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  showAllEduTypes={!pDetails.config.education}
                  heading={
                    pDetails.config.education && isArray(pDetails.config.education)
                      ? 'Education'
                      : 'Education (highest)'
                  }
                  isSpecific={
                    pDetails && pDetails.config.education
                      ? isArray(pDetails.config.education)
                        ? true
                        : false
                      : false
                  }
                  pDetails={pDetails}
                  addonDetails={props.createCandidateConfig.addons.educationHybrid || null}
                  price={props.createCandidateConfig.addons.educationHybrid.price}
                  values={values.educationHybrid}
                  onValueChange={(name, value) => {
                    handleMultipleConfigValueChange('educationHybrid', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Education REGIONAL_PARTNER addon */}
              {pDetails &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.educationThirdParty &&
              pDetails.config.educationType === 'REGIONAL_PARTNER' &&
              pDetails.config.education &&
              !disableAddon(pConfig ? pConfig.education?.length : 0, 'EDU') ? (
                <Education
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  heading={
                    pDetails.config.education && isArray(pDetails.config.education)
                      ? 'Education'
                      : 'Education (highest)'
                  }
                  isSpecific={
                    pDetails && pDetails.config.education
                      ? isArray(pDetails.config.education)
                        ? true
                        : false
                      : false
                  }
                  pDetails={pDetails}
                  addonDetails={props.createCandidateConfig.addons.educationThirdParty || null}
                  price={props.createCandidateConfig.addons.educationThirdParty.price}
                  values={values.educationRegionalPartner}
                  onValueChange={(name, value) => {
                    handleMultipleConfigValueChange('educationRegionalPartner', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}

              {pDetails &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              (props.createCandidateConfig.addons.education ||
                props.createCandidateConfig.addons.educationThirdParty ||
                props.createCandidateConfig.addons.educationHybrid) &&
              (!pDetails.config.education ||
                (!pDetails.config.education && isEmpty(pDetails.config.education)) ||
                pDetails.config.educationType === null) &&
              validateEducation(pDetails?.config?.education) ? (
                <Education
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  showAllEduTypes={true}
                  heading={'Education'}
                  isSpecific={false}
                  pDetails={pDetails}
                  addonDetails={props.createCandidateConfig.addons || null}
                  price={
                    props.createCandidateConfig.addons.education?.price ||
                    props.createCandidateConfig.addons.educationThirdParty?.price ||
                    props.createCandidateConfig.addons.educationHybrid?.price ||
                    null
                  }
                  values={values.education}
                  onValueChange={(name, value) => {
                    handleMultipleEduConfigValueChange('education', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Employment addon */}
              {pDetails &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.employment &&
              isEmpty(pDetails.config.employment) &&
              !disableAddon(pConfig ? pConfig.employment : 0, 'EMP') ? (
                <Employment
                  heading={'Employment (last)'}
                  price={props.createCandidateConfig.addons.employment.price}
                  values={values.employment}
                  pDetails={pDetails}
                  onValueChange={(value) => {
                    handleConfigChange('employment', null, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Reference addon */}
              {pDetails &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.reference ? (
                <Reference
                  heading={'Reference'}
                  price={props.createCandidateConfig.addons.reference.price}
                  values={values.reference}
                  onValueChange={(name, value) => {
                    handleConfigChange('reference', name, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                  packageReferenceCount={calculatePackageReferenceCount(pDetails.config)}
                />
              ) : null}
              {/* World addon */}
              {pDetails &&
              !pDetails.config.world &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.world ? (
                <BooleanField
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  value={(values && values.world) || ''}
                  price={props.createCandidateConfig.addons.world.price}
                  type={'world'}
                  heading={`World Check`}
                  onValueChange={(value) => {
                    handleConfigChange('world', null, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Drug Addon */}
              {pDetails &&
              (!pDetails.config.drug || pDetails.config.drug !== '5_PANEL') &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.drug5Panel &&
              pDetails.config.drug === null ? (
                <BooleanField
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  disabled={disableD5}
                  value={(values && values.drug5Panel) || ''}
                  price={props.createCandidateConfig.addons.drug5Panel.price}
                  type={'drug'}
                  heading={`Drug (5 Panel)`}
                  onValueChange={(value) => {
                    handleDrugAddonChange('drug5Panel', value);
                    handleConfigChange('drug5Panel', null, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Drug Addon */}
              {pDetails &&
              (!pDetails.config.drug || pDetails.config.drug !== '7_PANEL') &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.drug7Panel &&
              pDetails.config.drug === null ? (
                <BooleanField
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  disabled={disableD7}
                  value={(values && values.drug7Panel) || ''}
                  price={props.createCandidateConfig.addons.drug7Panel.price}
                  type={'drug'}
                  heading={`Drug (7 Panel)`}
                  onValueChange={(value) => {
                    handleDrugAddonChange('drug7Panel', value);
                    handleConfigChange('drug7Panel', null, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Drug Addon */}
              {pDetails &&
              (!pDetails.config.drug || pDetails.config.drug !== '10_PANEL') &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.drug10Panel &&
              pDetails.config.drug === null ? (
                <BooleanField
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  disabled={disableD10}
                  value={(values && values.drug10Panel) || ''}
                  price={props.createCandidateConfig.addons.drug10Panel.price}
                  type={'drug'}
                  heading={`Drug (10 Panel)`}
                  onValueChange={(value) => {
                    handleDrugAddonChange('drug10Panel', value);
                    handleConfigChange('drug10Panel', null, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {/* Cibil Addon */}
              {pDetails &&
              !pDetails.config.credit &&
              props.createCandidateConfig &&
              props.createCandidateConfig.addons &&
              props.createCandidateConfig.addons.cibil ? (
                <BooleanField
                  cPkgDetails={null}
                  sPkgDetails={null}
                  aPkgDetails={null}
                  value={(values && values.cibil) || ''}
                  price={props.createCandidateConfig.addons.cibil.price}
                  type={'credit'}
                  heading={`Credit Check`}
                  onValueChange={(value) => {
                    handleConfigChange('cibil', null, value);
                  }}
                  paymentModelType={paymentModelType}
                  accessLevel={accessLevel}
                />
              ) : null}
              {pDetails &&
                !pDetails.config.socialMedia &&
                props?.createCandidateConfig?.addons?.socialMedia && (
                  <SocialMedia
                    heading={'Social Media Check'}
                    price={props.createCandidateConfig.addons.socialMedia.price}
                    pDetails={(pDetails && pDetails.config.socialMedia) || null}
                    addonDetails={props.createCandidateConfig.addons.socialMedia || null}
                    values={values.socialMedia}
                    onValueChange={(type, value) => {
                      handleConfigChange(type, null, value);
                    }}
                    accessLevel={accessLevel}
                  />
                )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  );
};
